body {
    background-color: white;
    padding: 0;
    margin: 0;
}
html{
    margin: 0;
    padding: 0;
}
.container {
    width: 300px;
    padding: 16px;
    background-color: white;
    margin: 0 auto;
    margin-top: 100px;
    border-radius: 8px;

}
.header {
    text-align: center;
    padding-bottom: 20px;
}
.logo-image{
    width: 80px;
}
.login {
    width: 100%;
    padding: 15px;
    margin: 5px 0 22px 0;
    display: inline-block;
    border: none;
    box-sizing: border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing: border-box;
    background: #f1f1f1;
    border-radius: 10px;
}
.btn{
    background-color: #e97200;
    color: white;
    margin: 0 auto;
    padding: 16px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
    opacity: 0.9;
    border-radius: 10px;
}
.btn:hover {
    opacity:1;
}
.main-heading{
    background-color: #e97200;
    width: 100%;
    color: #fff;
    height: 50px;
    position: relative;
    vertical-align: middle;
}
.main-heading-login{
    background-color: #e97200;
    width: 100%;
    color: #fff;
    height: 50px;
    display: flex;
    justify-content: space-around;
    text-align: center;
    vertical-align: middle;
}
.main-heading-login h3{

}
.label-align-left{
    text-align: left;
    padding-bottom: 5px;
}
.bottom-footer{
    position: fixed;
    bottom: 0;
    text-align: center;
    width: 100%;
}
.loading-box{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.heading-display{
    background-color: #e97200;
    justify-content: space-around;
    text-align: center;
    vertical-align: middle;
    display: flex;
}
.main-container {
    width: 100%;
    margin: 0 auto;
}
.inner-container{
    width: 100%;
    margin: 0 auto;
}
.inner-container-work-orders{
    width: 100%;
    margin: 0 auto;
}
.input-container{
    padding-top: 10px;
}

.question-container{
    width: 100%;
    margin: 0 auto;
}

@media all and (min-width: 600px) {
    .question-container {
        width: 600px;
        margin: 0 auto;
    }
}

@media all and (min-width: 800px) {
    .inner-container-work-orders{
        width: 1000px;
        margin: 0 auto;
    }
    .inner-container {
        width: 800px;
        margin: 0 auto;
    }
    .main-container-stream {
        width: 800px;
        margin: 0 auto;
    }
}
@media all and (min-width: 1200px) {
   .main-container {
        width: 1200px;
        margin: 0 auto;
    }
}

.main-container-stream {
    width: 100%;
    margin: 0 auto;
}

.flex-space-between{
    display: flex;
    justify-content: space-between;
}

.fancy-button {
    background-color: #e97200;
    color: white;                          /* White text color */
    border: none;                          /* No border */
    padding: 15px 32px;                    /* Padding */
    text-align: center;                    /* Center text alignment */
    text-decoration: none;                 /* No text decoration */
    display: inline-block;                 /* Make it inline */
    font-size: 16px;                       /* And give it a nice size*/
    margin: 4px 2px;                       /* Some margin */
    cursor: pointer;                       /* Pointer cursor on hover */
    border-radius: 12px;                   /* Rounded corners */
    transition-duration: 0.4s;             /* Adds a transition */
}

.fancy-button-top {
    background-color: #fff;
    color: #e97200;                          /* White text color */
    border: none;                          /* No border */
    padding: 15px 32px;                    /* Padding */
    text-align: center;                    /* Center text alignment */
    text-decoration: none;                 /* No text decoration */
    display: inline-block;                 /* Make it inline */
    font-size: 16px;                       /* And give it a nice size*/
    margin: 4px 2px;                       /* Some margin */
    cursor: pointer;                       /* Pointer cursor on hover */
    border-radius: 12px;                   /* Rounded corners */
    transition-duration: 0.4s;             /* Adds a transition */
}

.fancy-button:hover {
    background-color: white;               /* White background on hover */
    color: #e97200;                        /* Original color for text on hover */
    border: 2px solid #e97200;             /* Border on hover */
}

.fancy-input {
    width: 100%;                               /* Maximum width */
    box-sizing: border-box;                    /* Content-box by default, border-box makes padding calculated as part of width */
    border: 2px solid #e97200;                 /* Border color same as button */
    border-radius: 4px;                        /* Mildly rounded border corners for that modern look */
    font-size: 16px;                           /* Text size */
    background-color: white;                   /* Background color */
    padding: 10px;                             /* Some padding */
    color: #e97200;                            /* Text color same as button color */
    transition-duration: 0.4s;                 /* Transition same as button */
}

.fancy-input:focus {
    border: 2px solid #e97200;                 /* Border color on focus (Change this if you want a different color on focus) */
    outline: none;                             /* Removes the browser default outline */
}

.fancy-input::placeholder {
    color: #e97200;                            /* Light text placeholder color same as button color */
    opacity: 0.5;                              /* Lower opacity to make it even lighter */
}

.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 500;
    height: 100vh;
}

.box {
    position: relative;
    max-width: 100vw;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    border: 1px solid #999;
    max-height: calc(100vh - 15vh - 40px);
    overflow-y: scroll;
}

.close-icon {
    content: 'x';
    color: black;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10000;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    font-size: 20px;
}
.input-container1 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-container1 input {
    flex-grow: 1;
    margin-right: 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 30px;
}

.input-container1 button {
    height: 30px;
    padding: 0 12px;
    background-color: #e97200;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.input-container1 button:hover {
    background-color: #cc5a00;
}
.selection{
    padding: 10px 10px 10px 10px;
    cursor: pointer;
}
.selection:hover{
    color: #e97200;
}

.answer-choice {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 200px;
    height: 50px;
    margin: 10px;
    padding: 10px;
    background-color: #e0f7fa;
    border-radius: 5px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.answer-choice span {
    font-weight: 500;
}

.close-btn {
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
}

.close-btn:hover {
    color: red;
}
.flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.group{
    border: 2px solid #e97200; /* The width, style, and color of the border */
    padding: 10px;            /* Space between the content and the border */
    margin: 10px;             /* Space around the border */
    border-radius: 5px;       /* Rounded corners */
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.search-container {
    display: flex;
    justify-content: center;
    padding: 20px;
}

.search-input {
    width: 100%;
    padding: 10px;
    font-size: 18px;
    border-radius: 25px;
    border: 1px solid #ccc;
}
.search-input-standard {
    padding: 10px;
    font-size: 18px;
    border-radius: 25px;
    border: 1px solid #ccc;
}
.search-input-user {
    width: 200px;
    padding: 10px;
    font-size: 18px;
    border-radius: 25px;
    border: 1px solid #ccc;
}

.icons-display {
    display: flex;
    position: absolute;
    right: 20px; /* Align to the right side */
    top: 50%; /* Align vertically to middle if icons height is not dynamic */
    transform: translateY(-40%); /* Align vertically to middle if icons height is not dynamic */
}
.action-button{
    background: rgb(233, 114, 0); border-color: rgb(233, 114, 0); color: rgb(255, 255, 255); padding: 5px; border-radius: 10px;
}
.display-users{
    width: 100%;
    padding: 60px 10px 10px 10px;
    border-collapse: collapse;
}

.display-users th{
    border-bottom: 2px solid black;
}
.display-users th, .display-users td {
    text-align: left;
    padding-bottom: 10px;
}
.display-users tr.select{
    cursor: pointer;
}

.post-title{
    font-size: .75em;
    color: gray;
}
.reset {
    all: initial;
    font-family: inherit;
    /* other properties as required */
}
.reset img {
    max-width: 100%;
    height: auto;
}
