body, input, textarea {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}




code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #e97200;
}

input:focus + .slider {
  box-shadow: 0 0 1px #e97200;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.ReactModalPortal {
  position: relative;
  z-index: 200 !important;
}
.fc .fc-button-primary { color: #FFF; background-color: #e97200 !important; border-color: #FFFFFF !important; }
/* Add these animations to your CSS file */
@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes slideBackIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.submenu-container {
  position: relative;
  width: 100%;
}

.submenu-container.slide-in {
  animation: slideIn 0.3s forwards;
}

.submenu-container.slide-out {
  animation: slideOut 0.3s forwards;
}

.submenu-container.slide-back-in {
  animation: slideBackIn 0.3s forwards;
}
.magazine-gallery-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

.magazine-gallery-title {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
  font-size: 32px;
  font-weight: 600;
}

/* Filter styles removed */

.magazine-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 30px;
  justify-content: center;
}

.magazine-item {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: inherit;
  transition: all 0.3s ease;
  border-radius: 8px;
  overflow: hidden;
}

.magazine-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.magazine-cover-container {
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  aspect-ratio: 3/4;
}

.magazine-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s ease;
}

.magazine-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(233, 114, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.magazine-item:hover .magazine-overlay {
  opacity: 1;
}

.magazine-item:hover .magazine-cover {
  transform: scale(1.08);
}

.view-text {
  color: white;
  font-weight: bold;
  font-size: 16px;
  padding: 10px 20px;
  border: 2px solid white;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.view-text:hover {
  background-color: white;
  color: #e97200;
}

.magazine-info {
  padding: 15px 10px;
  text-align: center;
  background-color: #f9f9f9;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.magazine-title {
  font-size: 15px;
  margin: 0 0 8px 0;
  line-height: 1.3;
  color: #333;
  font-weight: 600;
}

.magazine-date {
  font-size: 13px;
  color: #777;
  margin: 0;
}

.magazine-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 20px;
  text-align: center;
}

.loading-spinner {
  border: 4px solid rgba(233, 114, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #e97200;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 15px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.magazine-error, .no-magazines {
  text-align: center;
  padding: 40px 20px;
  font-size: 18px;
  color: #666;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin-top: 20px;
}

/* Responsive adjustments */
@media (max-width: 900px) {
  .magazine-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 25px;
  }
}

@media (max-width: 600px) {
  .magazine-gallery-title {
    font-size: 24px;
  }

  .magazine-grid {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap: 20px;
  }

  .magazine-title {
    font-size: 14px;
  }

  .magazine-date {
    font-size: 12px;
  }

  .view-text {
    font-size: 14px;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .magazine-gallery-container {
    padding: 20px 15px;
  }

  .magazine-grid {
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    gap: 15px;
  }

  .magazine-info {
    padding: 10px 5px;
  }

  .magazine-title {
    font-size: 12px;
    margin-bottom: 4px;
  }
}
/* Error container styles */
.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 20px;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 8px;
  border-left: 4px solid #e97200;
  margin: 20px 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.error-icon {
  font-size: 48px;
  color: #e97200;
  margin-bottom: 15px;
}

.error-title {
  font-size: 22px;
  color: #80561b;
  margin-bottom: 15px;
  font-weight: 600;
}

.error-message {
  color: #333;
  margin-bottom: 20px;
  line-height: 1.6;
  max-width: 450px;
}

.error-button {
  background-color: #e97200;
  color: white;
  border: none;
  padding: 10px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.error-button:hover {
  background-color: #ff8c2a;
}

@media (max-width: 768px) {
  .error-container {
    padding: 20px 15px;
  }

  .error-icon {
    font-size: 36px;
  }

  .error-title {
    font-size: 18px;
  }
}
/* Success Message */
.success-message {
  background-color: #dff0d8;
  color: #3c763d;
  border-left: 4px solid #5cb85c;
  padding: 12px 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  animation: fadeInOut 3s forwards;
}

@keyframes fadeInOut {
  0% { opacity: 0; }
  10% { opacity: 1; }
  90% { opacity: 1; }
  100% { opacity: 0; }
}

/* Profile Section */
.employee-profile {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 25px;
  margin-bottom: 30px;
}

.profile-header {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 20px;
}

.profile-photo {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 25px;
  border: 3px solid #e97200;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.photo-placeholder {
  width: 100%;
  height: 100%;
  background-color: #e97200;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  font-weight: bold;
}

.profile-title h2 {
  margin: 0 0 5px 0;
  color: #333;
  font-size: 24px;
}

.employee-position {
  color: #e97200;
  font-weight: 600;
  margin: 0 0 5px 0;
}

.employee-department {
  color: #80561b;
  margin: 0;
}

.profile-actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.profile-edit-button {
  background-color: #e97200;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.profile-edit-button:hover {
  background-color: #ff8c2a;
}

/* Profile Details */
.profile-details {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.detail-group {
  flex: 1;
  min-width: 250px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.detail-group h3 {
  color: #80561b;
  font-size: 18px;
  margin-bottom: 15px;
  padding-bottom: 5px;
  border-bottom: 2px solid #f0f0f0;
}

.detail-row {
  display: flex;
  margin-bottom: 10px;
}

.detail-label {
  font-weight: 600;
  min-width: 120px;
  color: #666;
}

.detail-value {
  color: #333;
}

/* Employee Self-Service Styles */

/* Success Message */
.success-message {
  background-color: #dff0d8;
  color: #3c763d;
  border-left: 4px solid #5cb85c;
  padding: 12px 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  animation: fadeInOut 3s forwards;
}

@keyframes fadeInOut {
  0% { opacity: 0; }
  10% { opacity: 1; }
  90% { opacity: 1; }
  100% { opacity: 0; }
}

/* Profile Section */
.employee-profile {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 25px;
  margin-bottom: 30px;
}

.profile-header {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 20px;
}

.profile-photo {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 25px;
  border: 3px solid #e97200;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.photo-placeholder {
  width: 100%;
  height: 100%;
  background-color: #e97200;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  font-weight: bold;
}

.profile-title h2 {
  margin: 0 0 5px 0;
  color: #333;
  font-size: 24px;
}

.employee-position {
  color: #e97200;
  font-weight: 600;
  margin: 0 0 5px 0;
}

.employee-department {
  color: #80561b;
  margin: 0;
}

.profile-actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.profile-edit-button {
  background-color: #e97200;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.profile-edit-button:hover {
  background-color: #ff8c2a;
}

/* Profile Details */
.profile-details {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.detail-group {
  flex: 1;
  min-width: 250px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.detail-group h3 {
  color: #80561b;
  font-size: 18px;
  margin-bottom: 15px;
  padding-bottom: 5px;
  border-bottom: 2px solid #f0f0f0;
}

.detail-row {
  display: flex;
  margin-bottom: 10px;
}

.detail-label {
  font-weight: 600;
  min-width: 120px;
  color: #666;
}

.detail-value {
  color: #333;
}

/* Form Styles */
.profile-form {
  background-color: #f9f9f9;
  padding: 25px;
  border-radius: 6px;
  margin-bottom: 30px;
  border-left: 4px solid #e97200;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.form-group {
  flex: 1;
  min-width: 200px;
  padding: 8px;
  margin-right: 15px;
  margin-bottom: 25px;
}

.form-group:last-child {
  margin-right: 0;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: #666;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  margin-top: 5px;
}

.form-group input:focus,
.form-group select:focus {
  border-color: #e97200;
  outline: none;
  box-shadow: 0 0 3px rgba(233, 114, 0, 0.3);
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.save-button {
  background-color: #e97200;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
  margin-left: 10px;
}

.save-button:hover {
  background-color: #ff8c2a;
}

.cancel-button {
  background-color: #ccc;
  color: #333;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.cancel-button:hover {
  background-color: #ddd;
}

/* Vehicles Section */
.vehicles-section {
  margin-top: 30px;
  border-top: 2px solid #f0f0f0;
  padding-top: 20px;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.section-header h3 {
  color: #80561b;
  margin: 0;
}

.add-vehicle-button {
  background-color: #e97200;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.add-vehicle-button:hover {
  background-color: #ff8c2a;
}

.add-vehicle-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.vehicle-form {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 6px;
  margin-bottom: 20px;
  border-left: 4px solid #e97200;
}

.vehicle-form h4 {
  margin-top: 0;
  color: #e97200;
  margin-bottom: 15px;
}

.vehicles-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.vehicle-card {
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.2s;
}

.vehicle-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.vehicle-info h4 {
  margin: 0 0 10px 0;
  color: #333;
}

.vehicle-details {
  display: flex;
  gap: 15px;
}

.vehicle-color, .vehicle-plate {
  font-size: 13px;
  padding: 3px 8px;
  border-radius: 12px;
  background: #f1f1f1;
  color: #666;
}

.vehicle-plate {
  font-weight: 600;
}

.vehicle-actions {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.edit-vehicle-button, .delete-vehicle-button {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.2s;
}

.edit-vehicle-button {
  background-color: #f0ad4e;
  color: white;
}

.edit-vehicle-button:hover {
  background-color: #ec971f;
}

.edit-vehicle-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.delete-vehicle-button {
  background-color: #d9534f;
  color: white;
}

.delete-vehicle-button:hover {
  background-color: #c9302c;
}

.delete-vehicle-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.no-vehicles {
  grid-column: 1 / -1;
  text-align: center;
  color: #777;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 6px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .profile-header {
    flex-direction: column;
    text-align: center;
  }

  .profile-photo {
    margin-right: 0;
    margin-bottom: 15px;
  }

  .form-row {
    flex-direction: column;
  }

  .form-group {
    margin-right: 0;
  }

  .profile-details {
    flex-direction: column;
  }

  .detail-group {
    margin-right: 0;
  }

  .vehicles-list {
    grid-template-columns: 1fr;
  }
}
/* Adding additional styles to the existing employee self-service styles */

/* Form section titles */
.form-section-title {
  color: #e97200;
  font-size: 18px;
  margin: 25px 0 15px 0;
  padding-bottom: 5px;
  border-bottom: 1px solid #f0f0f0;
}

.form-section-title:first-of-type {
  margin-top: 0;
}

/* Checkbox styling */
.checkbox-group {
  display: flex;
  align-items: center;
  padding-top: 12px;
}

.checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox-container input[type="checkbox"] {
  margin-right: 10px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.checkbox-text {
  user-select: none;
}

/* Vehicle tag styling */
.vehicle-tag {
  font-size: 13px;
  padding: 3px 8px;
  border-radius: 12px;
  background: #e0f0e0;
  color: #388e3c;
  font-weight: 500;
}

/* Read-only birthdate display */
.birthdate-display {
  color: #555;
  font-style: italic;
}

/* Address display */
.address-display {
  margin-top: 5px;
  color: #666;
  font-size: 14px;
}

/* Neighborhood badge */
.neighborhood-badge {
  display: inline-block;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 2px 10px;
  font-size: 13px;
  color: #666;
  margin-top: 5px;
}

/* Resident number badge */
.resident-number {
  background-color: #e97200;
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  display: inline-block;
  margin-left: 10px;
}

/* Enhanced photo styling for residents */
.profile-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.profile-photo:hover img {
  transform: scale(1.05);
}

/* Preference section styling */
.preferences-section {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.preference-title {
  font-weight: 600;
  margin-bottom: 10px;
  color: #555;
}

/* Vehicle card enhancements */
.vehicle-card {
  border-left: 3px solid #e97200;
  transition: all 0.3s ease;
}

.vehicle-card:hover {
  border-left-width: 5px;
}

/* Mobile optimizations */
@media (max-width: 768px) {
  .checkbox-group {
    padding-top: 0;
  }

  .form-section-title {
    font-size: 16px;
  }
}
